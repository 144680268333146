import { isGeolocationSupported, getLocation, subscribe, initLocationSearch, initGeolocation, validateInput } from '../_helpers/location';
import { validateInput as validateInputCoordinates, parseCoordinates } from '../_helpers/location-common';
const assetsLoaded = [];
const cssClassLoading = 'cockpit--view-loading';
const cssClassMap = 'cockpit--view-map';
const cssClassHideHeaderLocation = 'hide-header-location';
export function getMapUrlRegexp() {
    return /^#\/map\/([^\/]+)\/([^\/?]+)(\?.*)?$/;
}
export function getMapSearchUrlRegexp() {
    return /^#\/map-search\/([^\/]+),([^\/?]+)(\/.*)?$/;
}
export function getTargetUrl(location, query = '') {
    return `${window.location.origin}${window.location.pathname}#/map/${location.y},${location.x}/${encodeURIComponent(location.name)}${query ? ('?' + encodeURIComponent(query)) : ''}`;
}
export function hideLoader(el) {
    el.classList.remove(cssClassLoading);
}
export function showLoader(el) {
    el.classList.add(cssClassLoading);
}
function showIndexView(el) {
    hideLoader(el);
    el.classList.remove(cssClassMap);
    // Hide the header location switcher
    document.body.classList.add(cssClassHideHeaderLocation);
}
function showMapView(el) {
    // The loader will be hidden by the map itself
    el.classList.add(cssClassMap);
    // Show the header location switcher
    document.body.classList.remove(cssClassHideHeaderLocation);
}
function loadMap(el, config) {
    const mapSearchMatches = getMapSearchUrlRegexp().exec(window.location.hash);
    if (mapSearchMatches !== null) {
        const coordinates = parseCoordinates(mapSearchMatches[1], mapSearchMatches[2]);
        if (coordinates !== null) {
            window.location.href = getTargetUrl({
                y: coordinates[0],
                x: coordinates[1],
                name: coordinates[3] || `${coordinates[0]},${coordinates[1]}`,
            });
        }
        return;
    }
    if (!getMapUrlRegexp().test(window.location.hash)) {
        showIndexView(el);
        return;
    }
    // Init map view immediately if all assets have been already loaded
    if (assetsLoaded.length === config.scripts.length + config.styles.length) {
        showMapView(el);
    }
    else {
        // Otherwise ensure all assets are loaded first and only then init map view
        const promises = [];
        // Show the loader
        showLoader(el);
        // Load TableFilter BEFORE the map scripts, as there is some conflict there (with polyfills perhaps?)
        config.scripts.unshift('app/tablefilter/tablefilter.js');
        // Load all necessary scripts
        config.scripts.forEach((url) => {
            promises.push(new Promise((resolve) => {
                if (assetsLoaded.includes(url)) {
                    resolve();
                }
                else {
                    const script = document.createElement('script');
                    script.onload = () => assetsLoaded.push(url) && resolve();
                    script.src = url;
                    script.async = true;
                    document.head.appendChild(script);
                }
            }));
        });
        // Load all necessary styles
        config.styles.forEach((url) => {
            promises.push(new Promise((resolve) => {
                if (assetsLoaded.includes(url)) {
                    resolve();
                }
                else {
                    const style = document.createElement('link');
                    style.onload = () => assetsLoaded.push(url) && resolve();
                    style.href = url;
                    style.rel = 'stylesheet';
                    document.head.appendChild(style);
                }
            }));
        });
        Promise.all(promises).then(() => showMapView(el));
    }
}
function redirectToMap() {
    const location = getLocation();
    if (location === null) {
        return;
    }
    window.location.href = getTargetUrl(location);
}
function updateDomElements(locationButtonElement, searchInputElement) {
    const location = getLocation();
    if (location !== null) {
        locationButtonElement.disabled = false;
        searchInputElement.value = location.name;
    }
    else {
        locationButtonElement.disabled = true;
        searchInputElement.value = '';
    }
}
document.addEventListener('DOMContentLoaded', () => {
    const el = document.getElementById('app-cockpit');
    if (!el) {
        return;
    }
    const config = JSON.parse(el.dataset.cockpit);
    const locationButton = el.querySelector('[data-cockpit-location-button]');
    const searchInput = el.querySelector('[data-cockpit-search]');
    // Subscribe to the location change
    subscribe(() => updateDomElements(locationButton, searchInput));
    // Initialize search
    initLocationSearch(searchInput, el.querySelector('[data-cockpit-search-results]'), redirectToMap);
    // Power up the geolocation
    if (isGeolocationSupported()) {
        initGeolocation(el.querySelector('[data-cockpit-geolocation]'), redirectToMap);
    }
    // Power up the location link
    locationButton.addEventListener('click', e => {
        e.preventDefault();
        validateInput(searchInput, redirectToMap);
    });
    // Load the map on hash change
    window.addEventListener('hashchange', () => loadMap(el, config));
    loadMap(el, config);
    updateDomElements(locationButton, searchInput);
    const searchInputValue = searchInput.value;
    // Make the location link inactive if the location search changes and no location is selected
    searchInput.addEventListener('keyup', () => {
        if ((searchInputValue && searchInputValue === searchInput.value) || validateInputCoordinates(searchInput)) {
            locationButton.disabled = false;
        }
        else {
            locationButton.disabled = true;
        }
    });
});
